<template>
  <a-drawer
    width="50%"
    :label-col="4"
    :wrapper-col="14"
    :visible="openMaskScope"
    @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <!-- 条件搜索 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="列名">
              <a-input v-model="queryParams.columnName" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="列名描述">
              <a-input v-model="queryParams.columnDesc" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
              </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 数据展示 -->
    <a-table :loading="loading" :size="tableSize" rowKey="columnName" :columns="columns" :data-source="dataList"
             :pagination="false"
             :bordered="tableBordered">
        <span slot="status" slot-scope="text, record">
          <a-popconfirm ok-text="是" cancel-text="否" @confirm="updateMaskStatus(record)">
            <span slot="title">确认<b>{{ record.status === 0 ? '启用' : '停用' }}</b>
              {{ record.columnName }}的脱敏吗?
            </span>
            <a-switch checked-children="是" un-checked-children="否" :checked="record.status === 1"/>
          </a-popconfirm>
        </span>
    </a-table>
    <!-- 分页 -->
    <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParams.pageNum"
                  :total="total" :page-size="queryParams.pageSize" :showTotal="total => `共 ${total} 条`"
                  @showSizeChange="onShowSizeChange" @change="changeSize"/>
  </a-drawer>
</template>

<script>

import {maskScopes, updateMaskStatus} from '@/api/system/role'
import {tableMixin} from '@/store/table-mixin'
import {changeMaskStatus} from "@/api/system/mask";

export default {
  name: 'CreateMaskScopeForm',
  mixins: [tableMixin],
  components: {},
  data() {
    return {
      // 遮罩层
      loading: true,
      openMaskScope: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 脱敏数据表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        roleId: null,
        columnName: null,
        columnDesc: null,
        status: null
      },
      // 查询参数
      roleMask: {
        maskId: null,
        roleId: null,
        status: null
      },
      formTitle: '',
      columns: [
        {
          title: '是否脱敏',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
          align: 'center'
        },
        {
          title: '列名',
          dataIndex: 'columnName',
          align: 'center'
        },
        {
          title: '列名描述',
          dataIndex: 'columnDesc',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '脱敏方向',
          dataIndex: 'direction',
          align: 'center',
          customRender: function (e) {
            if (e == 1) {
              return "从右向左";
            } else {
              return "从左向右";
            }
          }
        },
        {
          title: '起始位置',
          dataIndex: 'startIndex',
          align: 'center'
        },
        {
          title: '脱敏字符数',
          dataIndex: 'wordNum',
          align: 'center'
        },
      ]
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询脱敏数据列表 */
    getList() {
      this.loading = true
      maskScopes(this.queryParams).then(response => {
        this.dataList = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    onClose() {
      this.openMaskScope = false
      this.queryParams.roleId = null;
    },
    // 取消按钮
    cancel() {
      this.openMaskScope = false
      this.queryParams.roleId = null;
    },

    /** 分配数据权限操作 */
    handleMaskScope(row) {
      this.formTitle = '设置脱敏权限'
      this.queryParams.roleId = row.id;
      this.roleMask.roleId = row.id;
      this.getList();
      this.openMaskScope = true;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        columnName: null,
        columnDesc: null,
        status: null,
      },
        this.handleQuery()
    },

    // 脱敏状态修改
    updateMaskStatus(row) {
      row.status = row.status == '0' ? '1' : '0'
      this.roleMask.status = row.status;
      this.roleMask.maskId = row.id;
      updateMaskStatus(this.roleMask).then(() => {
        this.$message.success('操作成功！')
        this.getList()
      }).catch(function () {
        row.status = row.status == '0' ? '1' : '0'
      })
    },

  }
}
</script>
